
@import "./uikit/internal/css/system";
@import "../node_modules/react-datepicker/dist/react-datepicker.css";

body {
    @extend %text-almost-black;
    // @extend %bg-neutral-lightest;
    background: #fcfcf9;
    -webkit-font-smoothing: subpixel-antialiased;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;

    *::selection {
        // background: rgba(get_color('green'), 0.3);
    }
}

html {
    box-sizing: border-box;
    font-family: sans-serif;
}

*, *::before, *::after {
    border-width: 0;
    border-style: solid;
    border-color: #dae1e7;
}
*, *::before, *::after {
    box-sizing: inherit;
}
