@import '../../../uikit/internal/css/system.scss';

.account-page {
    &__card {
        
    }

    &__card-title {
        margin-top: 0!important;
    }


    &__subscription-cards {
        display: flex;
        flex-wrap: wrap;
        @include smaller-than(tablet) {
            flex-direction: column;
        }
    }

    &__subscription-card {
        flex: 1;
        min-width: 240px;
        &:first-of-type {
            margin-right: get_spacing(4);
            @include smaller-than(tablet) {
                margin-right: 0;
                margin-bottom: get_spacing(4);
            }
        }

       
    }
}