@import '../../../../uikit/internal/css/system.scss';

.message-composition-bar {
    @extend %shadow-lg;
    background: white;

    &__container {
        max-width: $page-width;
        display: flex;
        margin: 0 auto;
        padding: get_spacing(2);
        align-items: center;
    }

    &__content-editor {
        padding: 0;
        margin-right: 12px;
        margin-left: 12px;
    }

    &__filler {
        flex: 1;
    }

    &__content-input {
        //  width: 100%;
        flex: 1;
        input {
            // border: none!important;
            box-shadow: none!important;
        }
    }

    &__icon-button {
        padding: 2px;
        width: 32px;
        height: 32px;
        box-shadow: none;
        border-radius: 0;
        margin: 0 get_spacing(2) get_spacing(2) get_spacing(2);
        // margin-bottom: get_spacing(2);
        svg {
            width: 28px;
            height: 28px;
            path {
                fill: get_color('grey');
            }
        }
        &:hover { box-shadow: none; transform: none; }
    }

    &__send-button {
        svg {
            path {
                fill: get_color('blue')!important;
            }
        }
    }

    &__image-preview {
        max-width: 160px;
    }
}
