@import "../../../../uikit/internal/css/system.scss";


.documents-detail-page {
    &__download-button {
        margin-top: 24px;
    }
    
    &__preview-image {
        margin-top: 24px;
        max-width: 300px;
        width: 100%;
    }
}