@import "../../../internal/css/system";


.ui-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.ui-list-item {
    @extend %rounded;
    border-bottom: 1px solid get_color('grey-light');
    padding: get_spacing(4);

    &:first-of-type {
        // border-top: 1px solid get_color('grey-light');
    }

    &:hover {
        @extend %bg-grey-light;
    }
    .ui-label {
        margin-top: 0!important;
        margin-bottom: 0!important;
    }
}