@import "../../../internal/css/system";


.ui-button {
    @extend %text-base;
    @extend %font-bold;
    @extend %standard-transition;
    @extend %rounded;     
    background: transparent;
    appearance: none;
    border:none;
    outline: none;
    cursor: pointer;
    height: 40px;

    -webkit-appearance: none;
    padding: get_spacing(2) get_spacing(4);

    &:hover {
        // @extend %shadow-md;
        // transform: translateY(-1px);
        &.link {
            transform: none;
            box-shadow: none;
        }
    }


    &:active {
        // @extend %shadow;
        transform: scale(0.98);
        // transform: translateY(1px);
    }
    
    svg {
        display: inline-block;
        margin-right: get_spacing(4);
    }

    &.subtle {
        // @extend %bg-neutral-light;
        background: $beige50;
        border-color: $beige70;
        border-width: 1px;
        border-style: solid;
        @extend %text-grey-dark;
        &:hover {
            background: $beige60;
            border-color: $beige70;
    
        }
        // &:active {
        //     background: $beige70;
        //     border-color: $beige70;
        // }
    }
    
    &.primary {
        @extend %text-white;
        background-color: $yellow50;
        border-color: $yellow70;
        border-width: 1px;
        border-style: solid;
        color: $black50;

        &:hover {
            background-color: $yellow70;
            border-color: $yellow70;
        } 
        &:active {
            // @extend %bg-blue-dark;
        }
    }


    &.link {
        color: $beige70;
        // @extend %text-blue;
        background:transparent;
        padding: get_spacing(2) get_spacing(0);


        &:hover {
            // @extend %text-blue-dark;
            color: $beige70;
            text-decoration: underline;
        }
    }
}