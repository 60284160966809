@import '../../../../uikit/internal/css/system.scss';


.discussion-messages {
    width: 100%;
    height: 100%;
    padding: get_spacing(6) 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}