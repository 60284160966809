@import "../../../internal/css/system";

.ui-date-field {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    &__input {
    }
}

.react-datepicker__time-list {
    padding: 0!important;
}

// .input {
//     // @extend %inputStyles;
//     margin: 8px 0;
//  }