@import "../../../../uikit/internal/css/system";


.calendar-component {
    height: calc(100% - 80px);
    position: absolute;
    margin: 0;
    max-width: none;

    &__toolbar-container {
        height: calc(100% - 80px);
        position: absolute;
        margin: 0;
    }

    &__calendar-container {
        height: calc(100% - 80px);
        border-radius: 8px;
        background: white;
        overflow: hidden;

        @include smaller-than(tablet) {
            height: calc(100% - 64px);
        }
    }

    &__target-element {
        margin: -1px;
        border: none;
        height: calc(100% + 2px);
        width: calc(100% + 2px);
    }
}

// .calendarContainer {

// }

// .targetElement {

// }