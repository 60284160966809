@import '../../../../uikit/internal/css/system';


.dashboard-page {
    &__section {
        margin-bottom: get_spacing(4);   
    }


    &__card-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .ui-label {
            margin: 0!important;
        }
    }
    
    &__section-title {
        text-transform: uppercase;
        margin-bottom: 0!important;
    }
    
}