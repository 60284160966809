@import "../../../../uikit/internal/css/system.scss";

.documents-upload-page {
    &__dropzone {
        margin-top: 24px;
        width: 100%;
        height: 150px;
        width: 100%; 
        padding: 24px;
        border: 2px dashed get_color('neutral-base')!important;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
            margin-top: 0;
        }
        input {
            width: 100%;
        }

        &.disabled {
            // border: 2px dashed $darkSecondaryTextColor!important;
            // height: 100px;
        }
    }

    &__image-preview {
        @extend %rounded;
        margin-top: get_spacing(4);
        max-width: 100%;
    }


    &__action-buttons {
        margin-top: get_spacing(4);
        button {
            margin-right: get_spacing(4);
        }
    }
}