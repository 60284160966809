// @import '../../../../components/shared-styles';


.login-page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;


    &__card {
        padding: 24px;
        max-width: 400px;
        width: 100%;
        margin-top: 24px;
        margin-bottom: 64px;
    }



    &__login-button {
        display: block;
        width: 100%;
        margin-top: 16px;
        * {
            // color: $primaryColor;
            text-decoration: none;
        }
    }
}

.content 
.title {
    max-width: 400px;
    width: 100%;
}

a {
    text-decoration: none;
}
