@import "../../../internal/css/system.scss";


.ui-image {
    img {
        cursor: pointer;
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: all;
    }

    &--expanded {
        display: flex;
        position: fixed;
        z-index: 20;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%!important;
        max-height: none;
        margin: 0;
        padding: 0;
        align-items: center;
        .ui-image__background {
            cursor: pointer;
            opacity: .8;
            pointer-events: auto;
        }
        .ui-image__container {
            max-width: 800px;
            height: initial;
            margin: 0 auto;
            object-fit: contain;
            position: relative;
            z-index: 10;
            position: relative;
            img {
                cursor: initial;
            }
        }
    }

    &--preview {
        max-width: 200px;
        // height: 200px;
        // object-fit: cover;
        // &.expanded {
        //     object-fit: initial;
        //     width: initial;
        //     height: initial;
        // }
    }


    &__background {
        position: absolute;
        background-color: black;
        // background-color: $systemSlateColor;
        pointer-events: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 200ms ease-in-out;
    }
}
