@import "../../../internal/css/shadow.scss";


.ui-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;

    &__backdrop {
        background: rgba(0, 0, 0, 0.25);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    &__container {
        // background: white;
        // border-radius: 6px;
        max-width: 450px;
        width: 100%;
        position: relative;
        z-index: 5;
    }
}
