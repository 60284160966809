@import "../../../internal/css/system";


.ui-form-field {
    display: block;
    margin-top: get_spacing(6);

    .ui-form-field__label {
        margin: get_spacing(1) get_spacing(1);
    }

    .ui-form-field__error {
        @extend %text-red;
        margin: get_spacing(1) get_spacing(1) get_spacing(4);
    }

    input {
        margin: 0 0;
        &.ui-checkbox {
            margin: 0 get_spacing(2);
        }
    }

}