@import "../../../../uikit/internal/css/system";

.calendar-event-form {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &__primary-info {
        padding: get_spacing(6);
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }


    &__primary-input {
        margin-right: 8px;
        margin-bottom: get_spacing(4);
    }


    &__additional-info {
        padding: get_spacing(6);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__secondary-input {
        margin-bottom: get_spacing(4) 
    }

    &__calendar-input {
        min-width: 100px;
        margin-bottom: get_spacing(4);
    }


    &__dates {
        display: flex;
        @include smaller-than(tablet) {
            flex-direction: column;
        }
    }

    &__date-picker-input {
        margin-bottom: get_spacing(4)!important;
        &:nth-of-type(1) {
            margin-right: get_spacing(4)!important;
            @include smaller-than(tablet) {
                margin-left: 0;
            }
        }
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 16px;
        button {
            margin-right: 16px;
        }
    }

}

