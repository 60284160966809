
@import "../../../internal/css/system";

.ui-select {
    position: relative;
    width: 100%;
    max-width: 300px;
    select {
        // @extend %bg-neutral-light;
        background: transparent;
        @extend %rounded;
        @extend %text-base;
        @extend %font-bold;
        appearance: none;
        -webkit-appearance: none;
        display: block;
        width: 100%;
        padding: get_spacing(3) get_spacing(8) get_spacing(3) get_spacing(4);
        
        border-width: 1px;
        border-color: $beige50;

        &:focus {
            background: transparent;
            border-color: $yellow50;
            outline: none;
        }
    }
    &__arrow {
        // pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker
        pointer-events: none;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        padding: 0 get_spacing(2);
        svg {
            height: 1rem;
            width: 1rem;
        }
    }
}