@import "../../../internal/css/system";


.ui-label {
    // @apply 
    @extend %text-black50;
    
    &.headline1 {
        @extend %text-5xl;
        @extend %font-bold;
        @extend %fontfamily-header;
        margin: get_spacing(10) 0;
    }

    &.headline2 {
        @extend %text-4xl;
        @extend %font-bold;
        @extend %fontfamily-header;
        margin: get_spacing(10) 0;
    }

    &.headline3 {
        @extend %text-3xl;
        @extend %font-bold;
        @extend %fontfamily-header;
        margin: get_spacing(10) 0;

    }

    &.headline4 {
        @extend %text-2xl;
        @extend %font-bold;
        @extend %fontfamily-header;
        margin: get_spacing(8) 0;
    }

    &.headline5 {
        @extend %text-xl;
        @extend %font-bold;
        @extend %fontfamily-header;
        margin: get_spacing(8) 0;

    }

    &.subtitle1 {
        @extend %text-base;
        @extend %font-bold;
        @extend %fontfamily-header;
        margin: get_spacing(10) 0;
        text-transform: uppercase; 
    }

    &.subtitle2 {
        @extend %text-sm;
        @extend %font-bold;
        margin: get_spacing(6) 0;
        text-transform: uppercase;
    }

    &.body1 {
        @extend %text-base;
        // leading-normal 
        @extend %fontfamily-content;
        margin: get_spacing(4) 0;
        line-height: 1.6rem;
    }

    &.body2 {
        @extend %text-sm;
        // leading-tight 
        @extend %fontfamily-content;
        margin: get_spacing(4) 0;
    }

    &.caption {
        @extend %text-xs;
        // font-semibold 
        @extend %font-semibold;
        @extend %fontfamily-content;
        text-transform: uppercase;
        margin: get_spacing(8) 0 0;

        & + .ui-label {
            margin-top: get_spacing(2);
        }
    }

}