@import '../../../../uikit/internal/css/system';

.create-contact-form {
    &__primary-info {
        padding: 0 0 get_spacing(6) 0;
        border-bottom: 1px solid get_color('grey');
        display: flex;
        flex-wrap: wrap;
    }

    &__primary-input {
        margin-right: get_spacing(4);
        @include smaller-than(tablet) {
            // margin-bottom: get_spacing(4);
        }
    }

    &__additional-info {
        padding: get_spacing(6) 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    
    &__secondary-input {
        margin-top: get_spacing(4);
        margin-right: get_spacing(4);
    }
    &__actions {
        margin-top: get_spacing(4);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        button {
            margin-right: get_spacing(4);
        }
    }
}


// .actions {
//     // background: $containerColor;
//     // border-top: 1px solid $borderColor;
//     padding: 24px 0;
//     display: flex;
//     align-items: center;
//     justify-content: flex-start;
//     button {
//         margin-left: 16px;
//     }
// }