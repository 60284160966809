@import '../../../../uikit/internal/css/system.scss';


.message-thread-page {
    padding: 0;
    &__thread-container{
        display: flex;
        padding: 0 get_spacing(4) get_spacing(12);
        min-height: calc(100% - 128px);
        @include smaller-than(small-tablet) {
            // padding-bottom: 50px;
        }
    }
    
    &__thread-footer-container {
        min-height: 64px;
        height: auto;
        position: fixed;
        width: 100%;
        margin: 0;
        bottom: 0px;
        left: 0;
        display: flex;
        align-content: center;
        justify-content: center;
        @include smaller-than(small-tablet) {
            min-height: 0;
            // margin: 0 24px;
        }
    }
    
    &__thread-footer {
        width: 100%;
    }
    
}
