


    
$font-families: (
    header: (-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif),
    content: (-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif),
);

$text-sizes: (
    xs: .75rem,
    sm: .875rem,
    base: 1rem,
    lg: 1.125rem,
    xl: 1.25rem,
    2xl: 1.5rem,
    3xl: 1.875rem,
    4xl: 2.25rem,
    5xl: 3rem,
);

$text-weights: (
    light: 300,
    regular: 400,
    semibold: 500,
    bold: 700,
);


@each $style in map-keys($font-families) {
    %fontfamily-#{$style} {
        font-family: map_get($font-families, $style);
    }
}

@each $style in map-keys($text-sizes) {
    %text-#{$style} {
        font-size: map_get($text-sizes, $style);
    }
}


@each $style in map-keys($text-weights) {
    %font-#{$style} {
        font-weight: map_get($text-weights, $style);
    }
}