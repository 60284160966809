
// $break-large: 840px; 
$break-medium: 720px; //600px;
$break-small: 480px;


@mixin respond-to($media) {
    @if $media == phone {
        @media only screen and (max-width: $break-small) { @content; }
    }
    @else if $media == tablet {
        @media only screen and (min-width: $break-small + 1) and (max-width: $break-medium) { @content; }
    }
    @else if $media == desktop {
        @media only screen and (min-width: $break-large + 1) { @content; }
    }
}

@mixin smaller-than($media) {
    @if $media == phone {
        @media only screen and (max-width: $break-small) { @content; }
    }
    @else if $media == tablet {
        @media only screen and (max-width: $break-medium) { @content; }
    }
    // Not necessary on desktop as it'd just be a normal style
    @else if $media == desktop { @content; }
}

@mixin larger-than($media) {
    @if $media == phone {
        @media only screen and (min-width: $break-small + 1) { @content; }
    }
    @else if $media == tablet {
        @media only screen and (min-width: $break-medium + 1) { @content; }
    }
    // Not necessary on desktop as it'd just be a normal style
    @else if $media == desktop { @content; }
}