@import "../../../../uikit/internal/css/system";

.calendar-toolbar {
    height: 100%;
    padding: 16px;
    margin:0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: none;

    &__calendar-title {
        margin: 0!important;
        flex: 1;
    }


    &__action-buttons {
        margin-right: get_spacing(2);
        button {
            padding: get_spacing(2) get_spacing(2);
            min-width: 0;
            border: none!important;
            background: transparent!important;
            box-shadow: none!important;
            transform: none!important;
        }
        svg {
            width: 16px;
            height: 24px;
            margin: 0;
        }
    }

    &__select {
        flex: 1;
    }
}

