@import '../../../../uikit/internal/css/system.scss';

.message-renderer {
    margin-top: get_spacing(4);  
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;


    &__message-inner {
        @extend %rounded;
        margin: get_spacing(2) 0;
        padding: get_spacing(2) get_spacing(4);
        background: white;
        border: 1px solid $grey60;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-width: 300px;
    }

    &__sender-name {
        margin: 0!important;
        padding: 0;
        flex: 1;
    }   

    &__time-stamp {
        margin: 0 get_spacing(1) 0 0;
        text-align: right;
    }

    &__message-body {
        margin: get_spacing(2) 0 0 get_spacing(1);
        padding: 0;
        flex-basis: 100%;
    }

    &__message-image {
        max-height: 250px;
        margin: get_spacing(4) 0 0;
    }

    &.message-renderer--is-sender {
        align-items: flex-end;
        .message-renderer__message-inner {
            background-color: $blue20;
            flex-direction: row-reverse;
            border: 1px solid $blue60;
        }

        .message-renderer__sender-name {
            // @extend %text-white;
            text-align: right;
        }

        .message-renderer__time-stamp {
            // @extend %text-white;
        }
        
        .message-renderer__message-body {
            // @extend %text-white;
            text-align: right;
        }
    }

}


 
// .messageImage {
//     max-height: 250px;
//     margin: 16px 0 0;
// }