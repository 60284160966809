
@import "../../../internal/css/system";

.ui-text-field {
    // @extend %bg-neutral-lightest;
    @extend %text-almost-black;
    @extend %rounded;
    @extend %text-base;
    @extend %font-semibold;
    @extend %standard-transition;
    display: inline-block;
    padding: get_spacing(3) get_spacing(4);
    border-width: 2px;
    border-color: transparent;
    width: 100%;
    max-width: 300px;
    border-color: $beige50;

    &::placeholder {
        @extend %text-grey;
    }
    &:hover {
        // @extend %bg-neutral-light;
        &::placeholder {
            // @extend %text-white;
            &:focus {
                @extend %text-grey;
            }
        }
    }
    &:focus {
        background: transparent;
        border-color: $yellow50; //get_color('blue-base');
        outline: none; 
    }
}