

.cancel-subscription {

    &__action-buttons {
        display: flex;
        flex-direction: row;
        .ui-button {
            // flex: 1;
        }
    }
}