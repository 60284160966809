@import '../../../../uikit/internal/css/system';

.dashboard-notification-card {
    @extend %rounded;
    // @extend %bg-white;
    @extend %bg-neutral-light;
    // @extend %shadow;
    margin: get_spacing(6) 0;

    height: 80px;
    max-width: 400px;
    width: 100%;
    padding: get_spacing(3);
    display: flex;
    align-content: center;
    justify-content: flex-start;
    flex-direction: row;

    // transition: mdc-elevation-transition-value();
    // @include mdc-elevation(0);
    // cursor: pointer;
    // &:hover {
    //     @include mdc-elevation(6);
    // }
    &__feature-graphic {
        // @extend %shadow;
        // background: white;
        @extend %bg-green-light;
        @extend %rounded;

        width: 56px;
        height: 56px;
        margin-right: 24px;
        // transition: mdc-elevation-transition-value();
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 36px;
            height: 36px;

            path {
                fill: white;
                // fill: get_color('almost-black');
            }
        }
    }

    &__feature-text {
        @extend %text-almost-black;
        flex: 1;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
    }

    &__feature-title {
        @extend %text-almost-black;
        margin: 0!important;
        // @include mdc-theme-prop(color, text-primary-on-light);
    }

    &__feature-description {
        @extend %text-almost-black;
        margin: 0!important;
        // @include mdc-theme-prop(color, text-primary-on-light);
    }


    // .dashboard-notification-card__feature-graphic {
    //     background: $systemGreenColor;
    // }
}