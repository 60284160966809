@import '../../../../uikit/internal/css/system.scss';

.contacts-detail-page {
    &__card {
        width: 100%;
    }

    &__secondary-info {
        margin-top: get_spacing(8);
    }

    &__contact-name {
        margin-bottom: 0!important;
    }

    &__emergency-title {
        @extend %text-red;
        margin-top: 0!important;
        margin-bottom: get_spacing(10)!important;
    }
    &__item {
        margin-bottom: get_spacing(8);
    }

    &__item-content {
        margin-top: get_spacing(2)!important;
    }

    &__item-title {
        margin: 0;
    }

    &__edit-button {
        display: block;
        margin-top: get_spacing(4);
    }

}

// .editButton {
//     display: block;
//     margin-top: 16px;
// }

// .card {
//     margin-top: 24px;
//     width: 100%;
// }

// .item {
//     margin-bottom: 16px;
// }

// .itemTitle {
//     margin: 0;
// }

// .itemContent {
//     margin:  4px 0 0 0;
// }