@import "../../../../uikit/internal/css/system";

.calendar-home-page {
    min-height: calc(100vh - 80px);
    padding: 0;
    margin-left: initial!important;
    margin-right: inital!important;
    margin-bottom: 0!important;
    max-width: none;
}
