@import '../../uikit/internal/css/system.scss';



.overflow-page {
    &__list-item {
        cursor: pointer;
        color: get_color('almost-black');

    }
    button {
        margin-top: get_spacing(4);
    }
}
// .page {
//     max-width: $pageWidth;
//     width: 100%;
//     margin: 0 auto;
// }