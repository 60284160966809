@import "../../../../uikit/internal/css/system";

.library-home-page {

    &__page-title {
        // @extend %text-green;
    }

    &__card {
        cursor: pointer;
        margin-top: get_spacing(10);
    }

    &__card-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .ui-label {
            margin: 0!important;
        }
    }
    &__card-icon {
        background: rgba(get_color('green'), 0.3);
        border-radius: 20px;
        width: 40px;
        height: 40px;
        padding: 8px;
        margin-right: get_spacing(3);

        svg {
            width: 24px;
            height: 24px;
            path {
                fill: get_color('green');
            }
        }
    }

    &__card-actions {
        margin-top: get_spacing(4);
    }

    &__card-action-caption {
        margin: get_spacing(4) 0 get_spacing(2)!important;
    }
    
    &__card-action {
        margin-right: get_spacing(4);
        &:nth-of-type(odd) {
            @include smaller-than(small-tablet) {
                // margin-right: 0;
                margin-bottom: get_spacing(4);
            }
        }
    }
}
