@import './scale';

$green: #2CB492;
$grey: rgb(115, 116, 116);
$green: rgb(58, 184, 127);
$red: rgb(254, 57, 46);




$black70: #000000;
$black60: #0D0E0E;
$black50: #252729;
$black40: #3D4144;
$black30: #555A5F;
$black20: #6E7479;

$grey80: #747476;
$grey70: #8e8e8f;
$grey60: #a7a7a9;
$grey50: #c1c1c2;
$grey40: #dbdbdb;
$grey30: #f4f4f5;

$yellow80: #d39100;
$yellow70: #ffb107;
$yellow60: #ffc13a;
$yellow50: #ffd16d;
$yellow40: #ffe1a0;
$yellow30: #fff1d3;

$green80: #223d3e;
$green70: #355e5e;
$green60: #477e7f;
$green50: #599FA0;
$green40: #78b3b4;
$green30: #99c6c6;
$green20: #bad8d8;

$blue80: #21465d;
$blue70: #2e6383;
$blue60: #3b7fa9;
$blue50: #5499c3;
$blue40: #7ab0d0;
$blue30: #a0c6dd;
$blue20: #c5ddeb;

$beige70: #ac9985;
$beige60: #c1b2a3;
$beige50: #eae5e0;
$beige40: #fffffe;


$color-palette: (
    
    'background': #fcfcf9,

    'white': #ffffff,

    'black70': #000000,
    'black60': #0D0E0E,
    'black50': #252729,
    'black40': #3D4144,
    'black30': #555A5F,
    'black20': #6E7479,

    'grey80': #747476,
    'grey70': #8e8e8f,
    'grey60': #a7a7a9,
    'grey50': #c1c1c2,
    'grey40': #dbdbdb,
    'grey30': #f4f4f5,

    'yellow80': #d39100,
    'yellow70': #ffb107,
    'yellow60': #ffc13a,
    'yellow50': #ffd16d,
    'yellow40': #ffe1a0,
    'yellow30': #fff1d3,

    'green80': #223d3e,
    'green70': #355e5e,
    'green60': #477e7f,
    'green50': #599FA0,
    'green40': #78b3b4,
    'green30': #99c6c6,
    'green20': #bad8d8,

    'blue80': #21465d,
    'blue70': #2e6383,
    'blue60': #3b7fa9,
    'blue50': #5499c3,
    'blue40': #7ab0d0,
    'blue30': #a0c6dd,
    'blue20': #c5ddeb,

    'beige70': #ac9985,
    'beige60': #c1b2a3,
    'beige50': #eae5e0,
    'beige40': #fffffe,

 

    'red': $red,

    'purple': #6772E5,
    
    'grey-light': rgb(236, 237, 241), //rgb(246, 247, 251), //, //lighten($grey, 45%),
    'grey-medium': lighten($grey, 25%),
    'grey': $grey,
    'grey-dark': $black30,
    'almost-black': $black50, 

    'text-muted': #fff, //map-deep-get($scale, neutral, N8), 
    'text-default': #fff, //map-deep-get($scale, neutral, N9), 
    'text-dark': #fff, //map-deep-get($scale, neutral, N10), 
    'text-selected': #fff, //map-deep-get($scale, blue, B9),

    // New colors
    'neutral-lightest': #EBECF0,
    'neutral-light': #DFE1E6, 
    'neutral-medium': #B3BAC5,
    'neutral-base': #7A869A, 
    'neutral-dark': #344563, 

    'blue-lightest': #DEEBFF,
    'blue-light': #4C9AFF,
    'blue-medium': #2684FF, 
    'blue-base': #0065FF, 
    'blue': #0065FF, 
    'blue-dark': #0052CC, 
    'blue-darkest': #0747A6,

    'green-lightest': #E3FCEF,
    'green-light': #79F2C0,
    'green-medium': #00875A,
    'green': #36B37E,
    'green-base': #36B37E,
    'green-dark': #00875A,

    'teal-lightest': #f1fbfc,
    'teal-light': #d2eef3,
    'teal-base': #14b5d0,
    'teal-dark': #007489,

);

@function get-color($color-name){
    @return map_get($color-palette, $color-name);
}

@each $style in map-keys($color-palette) {
    %text-#{$style} {
        color: map_get($color-palette, $style);
    }
    %bg-#{$style} {
        background-color: map_get($color-palette, $style);
    }
    %border-#{$style} {
        border-color: map_get($color-palette, $style);
    }
}