@import "../../../../uikit/internal/css/system";

// No view encapsulation so everythings in here
.fullcal-calendar-element {
    * {
        // border-color: $borderColor!important;
    }
    width: 100%;
    height: 100%;
    background: white;
    position:relative;
    /*
    Fullcal override styles
    */
    // Reset styles for top toolbar
    .fc-toolbar {
        display: none;
        // height: 80px;
        // padding: 24px;
        // margin:0;
        // margin-bottom: 0!important;
        // display: flex;
        // align-items: center;
        // justify-content: flex-start;
        // .fc-left, .fc-right, .fc-center {
        //     float: none
        // }
        // .fc-left {
        //     flex: 1;
        //     h2 {
        //         @extend %subtitle--regular;
        //     }
        // }
    }

    .fc-popover.fc-more-popover {
        padding: 12px;
        border-radius: 4px;
        // @include mdc-elevation(6);
        .fc-header {
            background: none;
            margin-bottom: 16px;
            .fc-title {
                text-align: center;
                text-transform: uppercase;
                line-height: 1;
                width: 30px;
                // font-family: map-get($font-families, content);
                // font-weight: map-get($font-weights, medium);
                //-- @extend %caption--bold;
                &:first-line {
                    //@extend %title--semibold;
                }
            }
        }
        .fc-event-container {
            padding: 0;
        }
    }

    .fc-day-grid-event.fc-h-event {
        padding: 2px 4px;
        margin-left: 4px;
        position:relative;
        background: $blue50;
        overflow: hidden;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border: none;
        // @include mdc-theme-prop(border-color, secondary);
        // border-left-width: 4px;
        // border-left-style: solid;
        // border-color: $primaryColor!important;
        &:after {
            position: absolute;
            content: ' ';
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            // background: map_get($featureColors, calendar); //$secondaryColor;
            // opacity: 0.54;
        }
        .fc-content {
            display: flex;
            flex-direction: row-reverse;
            // padding-right: 8px;
            // align-items: center;
            .fc-title {
                flex: 1;
                text-align: left;
                display: block;
                max-width: 100%;        
                width: auto;
                overflow: hidden;
                // text-overflow: ellipsis;
                // padding-right: 8px;        
                //-- @extend %caption--regular;
                // color: $lightTextColor;

                // font-family: map-get($font-families, content);
                // font-weight: map-get($font-weights, regular);
                // @include mdc-theme-prop(color, text-primary-on-light);
            }
            .fc-time {
                display: none;
                // width: 40px;
                // text-align: right;
                // @extend %body--regular;
                // color: $darkTextColor;
                //@extend %body--small-semibold;
                // @include mdc-theme-prop(color, text-primary-on-light);
            }
        }
    }

    .fc td, .fc th {
        border-left: 0!important;
    }

    .fc-more-cell {
        //-- @extend %body--semibold;
    }

    // MONTH VIEW RESETS
    .fc-month-view {
        // Reset styles for day of week
        // .fc-head {
        //     position:absolute;
        //     z-index: 5;
        // }
        // Make header not have a border
        .fc-day-header,
        .fc-head thead,
        .fc-widget-header,
        .fc-head-container  {
            // background: $systemWhiteColor; // $systemLightGrayColor;
            // border: none;
        }

        // Style the dow text
        .fc-day-header {
            text-align: left;
            padding: 16px 0px;
            text-transform: uppercase;
            // color: $darkSecondaryTextColor;
            text-align:center;
            // font-family: map-get($font-families, headers);
            // font-weight: map-get($font-weights, medium);
        }

        // Reset styles for the table cells
        .fc-day {
            border-color: rgba(0, 0, 0, 0.08);
        }
        // Style the DoM text
        .fc-day-top {
            padding: 8px;
            a {
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
            }
        }
        // Style the DoM text
        .fc-day-number {
            //@extend %body--small-semibold;
            // color: $darkTextColor;
        }
        // set styles for days on other months
        .fc-other-month {
            background: rgb(248, 248, 248);
            .fc-day-number {
                // color: $darkSecondaryTextColor;
            }
        }
        // Today is default highlighted, make it not
        .fc-day.fc-today {
            background: none
        }
        // Instead, highlight the number
        .fc-day-top.fc-today {
            a {
                // background: $primaryColor;
                // color: $onPrimaryColor;
                border-radius: 4px;
                // border-radius: 50%;
            }
        }
    
    }

    .fc-agendaWeek-view,.fc-agendaDay-view, .fc-agendaThreeDay-view {

        .fc-head-container, .fc-axis.fc-widget-header, .fc-day-header.fc-widget-header {
            border-bottom-width: 0px;
        }
        .fc-body > tr > .fc-widget-content{
            border-top-width: 0px;
        }

        // Eliminate dotted lines 
        .fc-time-grid .fc-slats .fc-minor td {
            border-top: none;
        }

        .fc-divider {
            padding: 0;
        }

        .fc-widget-header {
            // background: $systemWhiteColor; // $systemLightGrayColor;

        }

        .fc-day-header {
            border-bottom: none;
            text-align: left;
            padding: 12px;   
            // background: $systemLightGrayColor;
            text-transform: uppercase;

            a, span {
                display: block;
                // color: $darkTextColor;

                // //--@extend %body--regular;
                // font-family: map-get($font-families, headers);
                // font-weight: map-get($font-weights, medium);

                &:first-line {
                    
                }
            }

        }

        .fc-bg .fc-today {
            background: white;
        }

        .fc-axis:not(.fc-time) {
            opacity: 0;
        }

        // tr[data-time="00:00:00"] {
        //     .fc-axis.fc-time span {
        //         display: none;
        //     }
        // }
    
        .fc-axis.fc-time {
            border: none;
            position: relative;
            right: 8px;
            // top: -12px;
            text-transform: uppercase;
            // color: $darkTextColor;
            // font-family: map-get($font-families, content);
            // font-weight: map-get($font-weights, medium);
            
            // @include mdc-typography(caption);
            // @include mdc-theme-prop(color, text-secondary-on-light);
            @include smaller-than(small-tablet) {
                border-right: none;
            }
        }
    }

    .fc-agendaDay-view {
        // Eliminate dotted lines 
        .fc-time-grid .fc-slats .fc-minor td {
            border-top: none;
        }
    }
}

