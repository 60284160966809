@import './scale';
@import './palette';


$shadowColor: rgba(map-get($color-palette, 'neutral-base'), 0.31);
$shadows: (
    'shadow': (0px 2px 4px -1px $shadowColor, 0 0 1px $shadowColor),
    'shadow-md': (0 4px 8px -2px $shadowColor, 0 0 1px $shadowColor),
    'shadow-lg': ( 0 8px 16px -4px $shadowColor, 0 0 1px $shadowColor),
);
 


@each $style in map-keys($shadows) {
    %#{$style} {
        box-shadow: map_get($shadows, $style);
    }
}