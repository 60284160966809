@import "../../../internal/css/system";

.contained-icon {
    // background: rgba(get_color('green'), 0.3);
    // @extend %bg-green-lightest;
    // background-color: $yellow60;
    border-radius: 20px;
    width: 40px;
    height: 40px;
    padding: 8px;
    margin-right: get_spacing(1);

    svg {
        width: 24px;
        height: 24px;
        path {
            fill: $black40;
            // fill: get_color('green');
        }
    }
}