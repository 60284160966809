

@function map-deep-get($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }
    @return $map;
}

@import './palette';
@import './text';
@import './spacing';
@import './shadow';
@import './media';

$navigation-bar-height: 64px;
$page-width: 60rem;


%rounded {
    border-radius: 4px;
}

%standard-transition {
    transition: 0.25s cubic-bezier(0.17, 0.67, 0.52, 0.97);
}


.container {
    max-width: 60rem;
    margin: $navigation-bar-height auto 0;
    padding: get_spacing(4) get_spacing(8);
}

.divider {
    margin: get_spacing(4) 0;
}
  
