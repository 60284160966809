@import "../../../internal/css/system";

.ui-card {
    // @extend %text-white;
    // max-w-sm bg-white rounded overflow-hidden'
    @extend %rounded;
    @extend %standard-transition;
    // @extend %shadow;
    border: 1px solid $grey40;
    @extend %bg-white;

    transition-property: transform;

    .ui-label.subtitle1, .ui-label.headline5 {
        margin: get_spacing(2) 0;
    }

    // &__raised {
    //     // @extend %shadow-md;
    //     // @extend %shadow;

    // }

    // &__subtle {
    //     @extend %bg-grey-light;
    // }

}


.ui-card-content {
    padding: get_spacing(6) get_spacing(8);
}