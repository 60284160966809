@import "../../../internal/css/system";


.ui-navigation-bar {
    @extend %bg-white;
    border-bottom: 1px solid $grey50;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0 auto;
    width: 100%;
    z-index: 10;

    &__top-row {
        width: 100%;
        max-width: $page-width;
        height: $navigation-bar-height;
        display: flex;
        align-items: center;
        justify-content: center;  
        margin: 0 auto;
    }

    &__breadcrumbs {
        background-color: $yellow50;
        width: 100%;
        height: 36px;
    }

    svg {
        display: inline-block;
        margin-right: get_spacing(2);
        width: 24px;
        path {
            fill: get_color('grey-medium');
        }
    }

    @include smaller-than(tablet) {
        &__logo {
            display: none!important;
        }
        &__feature {
            .ui-label {
                display: none;
            }
        }
        svg {
            margin-right: 0;
        }
    }

    &__logo {
        width: $navigation-bar-height + 8px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        border-right: 1px solid get_color('grey-light');
        border-left: 1px solid get_color('grey-light');
        * {
            margin: 0!important;
        }
    }

    &__feature {
        cursor: pointer;
        height: 100%;
        // max-width: 150px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid $grey50;

        * {
            color: $black30;
            font-size: map_get($text-sizes, base)!important;
            font-weight: 400!important;
            @extend %fontfamily-header;
        }

        &.selected {
            // background-color: $yellow50;
            color: $black50;
            // * { @extend %text-white; }
            svg {
                path {
                    fill: $black50!important;
                }
            }
        }
    }

    &__title {
        @extend %text-almost-black;
        margin: 0;
        padding-left: get_spacing(4);
    }
}