@import "../../../internal/css/system";

.page-template {
    &__content {
        margin: $navigation-bar-height auto get_spacing(8);
        max-width: $page-width;
        padding: get_spacing(4) get_spacing(4);

        @include smaller-than(tablet) {
            padding: get_spacing(2) get_spacing(4);
        }

        &--no-header {
            margin-top: 0!important;
        }
    }
}