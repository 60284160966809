// @import '../../../../components/shared-styles';


@import "../../../../uikit/internal/css/system.scss";

.landing-page {
    margin: 0!important;
    // padding: 0!important;
    padding: get_spacing(8);
    // position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    max-width: none;

    &__container {
        flex: 1;
        width: 50%;
        img {
            @extend %rounded;
            width: 100%;
            height: 100%;
            object-position: center center;
            object-fit: cover;
        }
    }

    &__container-left {
        padding: get_spacing(4);
        flex: 1;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        .landing-page__inner {

        }
    }

    &__container-right {
        flex: 1.5;
    }
    
    @include smaller-than(tablet) {
        padding: get_spacing(4);
        flex-direction: column-reverse;

        &__container {
            width: 100%;
            img {
                height: auto;
                width: 100%;
                
            }
        }

        &__container-right {
            flex: 0;
        }
    }
   
    &__links {
        .ui-label {
            display: inline;
            padding: 0 get_spacing(2);
        }
    }
}