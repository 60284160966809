

$spacing: (
    0: 0rem,
    1: 0.25rem,
    2: 0.5rem,
    3: 0.75rem,
    4: 1rem,
    5: 1.25rem,
    6: 1.5rem,
    8: 2rem,
    10: 2.5rem,
    12: 3rem,
);

$sides: (
    t: 'top', 
    b: 'bottom',
    l: 'left',
    r: 'right'
);

@function get-spacing($spacing-name) {
    @return map_get($spacing, $spacing-name);
}

@each $style in map-keys($spacing) {
    @each $side in map-keys($sides) {
        %p#{side}-#{style} { padding-#{map_get($sides, $side)}: map_get($spacing, $style); }
        %m#{side}-#{style} { margin-#{map_get($sides, $side)}: map_get($spacing, $style); }
    }
    %px-#{$style} { padding-left: map_get($spacing, style); padding-right: map_get($spacing, style); }
    %py-#{$style} { padding-left: map_get($spacing, style); padding-bottom: map_get($spacing, style); }
    %mx-#{$style} { margin-left: map_get($spacing, style); margin-right: map_get($spacing, style); }
    %my-#{$style} { margin-left: map_get($spacing, style); margin-bottom: map_get($spacing, style); }
}