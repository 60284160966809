@import '../../../../uikit/internal/css/system.scss';

.page {
    margin-left: 0;
    // max-width: $pageWidth;
    // margin: 0 auto;
}

.section {
    // border-bottom: 1px solid $borderColor;
}
.lastSection {
    padding: 24px 0;
}

.sectionSubTitle {
    margin: 0 0 16px 0;
}

.pageTitle {
    margin-bottom: 24px;
}

.invite {
    display: flex;
    align-items: center;
    flex-wrap: wrap; 
    margin: 16px 0;
    .inviteText {
        flex: 1;
        margin: 0;
    }
    .inviteActionButton {
        &:nth-of-type(2) {
            margin: 0 0 0 16px;
        }
    }
}

.emailField {
    display: block;
    max-width: 300px;
    margin: 16px 0;
    width: 100%;
}